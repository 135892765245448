
// use httpd-vhosts.conf to run login with existing DAP SSO configuration  
// Add following configuration
// ProxyPass "/" "http://localhost:4200/" timeout=300
// ProxyPassReverse "/" "http://localhost:4200/" timeout=300

export const environment = {
    production: false,
    apiUrl: 'https://hrms-rag-qa-api.tenupsoft.com/',
    socketURL: 'wss://hrms-rag-qa-api.tenupsoft.com/ws/chat/'
};
