import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { appConstant } from '../constant/app-constant';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) { }

    getLoginUrl(): string {
        // return `https://breezy-bananas-pick.loca.lt/realms/master/protocol/openid-connect/auth?response_type=code&client_id=RAG-POC&redirect_uri=${appConstant.REDIRECT_URI}`;
        return `https://identity.tenupsoft.com/auth/realms/TenupDev/protocol/openid-connect/auth?response_type=code&client_id=hrms-dev&redirect_uri=${appConstant.REDIRECT_URI}`;
    }

    getLoginUserDetails(code: any): Observable<any> {
        return this.http.get(`${environment.apiUrl}auth/exchange_code/?code=${code}&state=${btoa(appConstant.REDIRECT_URI)}`);
    }

    isLoggedIn(): boolean {
        let token: any = localStorage.getItem('access_token');
        if (token) {
            return true;
        }
        return false;
    }

    logout() {
        localStorage.removeItem('access_token');
        window.location.href = this.getLoginUrl();
    };

    showForbiddenError() {
        this.router.navigate(['403']);
    }

    getUserId() {
        return localStorage.getItem('userId');
    }

}
