import { environment } from "../../../environments/environment";

let APP_BASE_URL = window.location.protocol + "//" + window.location.hostname;

if (window.location.hostname === 'localhost') { 
    APP_BASE_URL = window.location.protocol + "//" + window.location.host;
}

export const appConstant = {
    REDIRECT_URI: `${APP_BASE_URL + '/login'}`,
    // HRMS_LOGIN_URL: `${environment.apiUrl}authentication/login?redirect_uri=${APP_BASE_URL + '/login'}`
};